<script>
  export default {
    data() {
      return {
        isDrawerOpen: false,
        currentSlide: 0,
        slideCount: 3,
        slideDuration: 4400,
        currentScrollY: 0
      }
    },
    computed: {
      // stateStatus() {
      //   return this.$store.getters.getStatus;
      // }
    },
    created() {
      // this.$store.dispatch('updateStatus', 'state updated');
    },
    mounted() {
      this.headerEffect();
      window.addEventListener('load', () => {
        this.loading();
      });
      window.addEventListener('scroll', () => {
        this.headerEffect();
      });
    },
    methods: {
      drawer() {
        this.isDrawerOpen = !this.isDrawerOpen;
      },
      slide() {
        this.currentSlide = 1;
        setInterval(() => {
          if(this.currentSlide < this.slideCount) {
            this.currentSlide++;
          } else {
            this.currentSlide = 1;
          }
        }, this.slideDuration)
      },
      loading() {
        if(this.$refs.loading_screen && this.$refs.loading_screen_logo) {
          return new Promise((resolve) => {
            this.$refs.loading_screen_logo.classList.add('js-loading-screen-logo--shown');
            setTimeout(() => {
              this.$refs.loading_screen.classList.add('js-loading-screen--hidden');  
              resolve();
            }, 2800);
          }).then(() => {
            this.slide();
          }).catch((error) => {
            return error;
          });
        }
      },
      headerEffect() {
        this.currentScrollY = window.scrollY;
        if(this.currentScrollY < 500) {
          this.$refs.header_element.classList.remove('js-header--hidden');
        } else {
          this.$refs.header_element.classList.add('js-header--hidden');
        }
      }
    }
  }
</script>

