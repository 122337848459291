'use strict';

// stylesheet import
import '../sass/main.sass';

import { createApp } from 'vue';
// import { store } from './store.js';
import App from './vue/app.vue';
import { IntersectionObserverToggleClass } from './modules/class-intersection-observer-toggleclass.js';
import { IsLegacyIE } from './modules/checking-legacy-ie.js';
import { FindOS } from './modules/checking-os.js';
import { FindBrowser } from './modules/checking-browser.js';
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
// const $ = require('jQuery');

// Vue.js
const app = createApp(App);
app.mount('#app');
  
// GSAP
gsap.registerPlugin(ScrollTrigger);
ScrollTrigger.batch('.js-animation-element', {
  onEnter: (batch) => {
    gsap.fromTo(batch, 
      {
        y: 10,
        autoAlpha: 0
      },
      {
        y: 0,
        autoAlpha: 1,
        delay: 0.1,
        duration: 1.4,
        ease: 'power3.out',
        scrollTrigger: {
          trigger: '.js-animation-trigger',
          start: 'bottom 70%',
          // markers: true
        }
      }
    )
  },
  once: true
});


// jQuery
// $(function() {
//   console.log('jQuery is ready.');
// });

// Intersection Observer
new IntersectionObserverToggleClass({
  selector: '.observe_target', 
  className: 'observed', 
  toggle: true
});

// checking legacy IE
IsLegacyIE();

// checking OS
FindOS();

// checking browser
FindBrowser();